<template>
  <div>
    <loading v-if="showLoading"></loading>
    <div v-if="errorMessage" class="container error-message-box justify-content-center align-items-center">
      <div class="text-center" >
        <h5>{{ errorMessage }}</h5>
        <b-button @click="cancel" variant="success" class="mb-1 mt-3">
          回官方帳號
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mapActions } from "vuex";
import liff from "@line/liff";

export default {
  data() {
    return {
      showLoading: true,
      errorMessage: ""
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions({
      getMessages: "liffChatroom/getMessages",
      sendMessage: "liffChatroom/sendMessage",
      goToChat: "liffChatroom/goToChat",
    }),
    async init () {
      let sourceType = this.$route.query.source_type;
      let sourceId = this.$route.query.source_id;
      let chatroomId = this.$route.query.chatroom_id;

      if (!sourceType || !sourceId) {
        this.showLoading = false;
        // this.$swal.fire({
        //   type: "error",
        //   text: "網址錯誤，請使用正確網址進入"
        // });
        this.errorMessage = "網址錯誤，請使用正確網址進入"
        return;
      }

      try {
        let payload = (sourceType === 'external') ? {sourceType, sourceId, chatroomId} : {sourceType, sourceId};
        let data = await this.goToChat(payload);
        if (data.id) {
          this.$router.push({
            name: "LiffChatroomShow",
            params: {
              orgCode: this.$route.params.orgCode,
              id: data.id
            },
          });
          return;
        } else {
          throw false;
        }
      } catch (error) {
        this.showLoading = false;

        let message = "系統錯誤";

        if (error.status === 404) {
          message = "找不到聊天室";
        }
        if (error.response.status === 'failed') {
          message = error.response.message;
        }

        // this.$swal.fire({
        //   type: "error",
        //   text: message,
        // });
        this.errorMessage = message
      }
    },
    cancel() {
      liff.closeWindow();
    },
  }
}
</script>
<style lang="scss" scoped>
  .error-message-box {
    height: 100vh;
  }
</style>